.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.h1{
  font-size: 3.2em;
  line-height: 1.1;
}

.h3{
  font-family: "Inter Tight", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  
}
.logo{
  display: flex;
  width: 100%;
  min-width: 150px;
  border-radius: 15px;
 
}
.container{
  display:flex;
  justify-content: space-around;
  align-items: center;
}



.firstRow{ /* How it works button row */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100vh;
  width: 33vw;
  margin-right: 5vw;
}

.secondRow{ /* Generate button row */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 45vw;
  
}

.thirdRow{  /* Progress bar row */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  width: 33vw;
  margin-left: 5vw;
  
}

.buttonContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  height: 20vh;
}

.FAQbuttonContainer{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width:100%;
  height: 20vh;
}

.howItWorksbuttonContainer{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width:100%;
  height: 20vh;
  
}

.button{
  height:40px;
  width: 55px;
  background-color: #f9f9f9;
  color: black;
  cursor: pointer;
}
button:hover {
  background-color: black;
  color:white;
}

.generateButton{
  height:55px;
  background-color: #f9f9f9;
  font-weight: 900;
  cursor: pointer;
  
  
}

.progressBarContainer{
  margin-top:20%;
  display: flex;
  justify-content:flex-start;
  height:80vh;
  width:100%;
  
}

.progress-container {
  width: 20px;              /* Width of the progress bar */
  height: 100%;            /* Height of the container */
  background-color: #e0e0e0; /* Background color of the container */
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-left: 1rem;
}

.progress-bar {
  width: 100%;              /* Full width to cover the container */
  height: 0;                /* Initial progress */
  background-color: #FFA500; /* Progress bar color */
  position: absolute;
  bottom: 0;                /* Starts from the bottom */
  transition: height 0.1s ease;
}

.logoContainer{
  margin-top: 10%;
  display: flex;
  justify-content: center;
  width: 50%;
  
}
.startupNameContainer{
  display: flex;
  justify-content: center;
  text-align:center;
  text-decoration: #e0e0e0;
  height: 20vh;
  width: 50vw;
  margin-bottom: 10%;
  
  
}

.descriptionContainer{
  display:flex;
  justify-content:center;
  align-items: flex-end;
  height: 20vh;
  width: 100vw;
  
}

.highlight{
  background-color: #f9f9f9;
}



@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
